export const configurationCategoriesList = {
  compliance: 'Compliance',
  configuration: 'Configuration',
  deviceAccess: 'Device Access',
  enterpriseSettings: 'Enterprise Settings',
  monitoring: 'Monitoring',
  network: 'Network',
  osMgmt: 'OS Management',
  productivity: 'Productivity',
  remoteMgmt: 'Remote Management',
  reporting: 'Reporting',
  security: 'Security',
  swMgmt: 'SW Management',
};

const configurationCategoriesConst = {
  darwin: {
    activationLockdown: {
      name: 'activation_lock_darwin',
      categories: [configurationCategoriesList.security,
        configurationCategoriesList.compliance],
    },
    appStore: {
      name: 'app_store_darwin',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.swMgmt],
    },
    automaticMacOsUpdates: {
      name: 'automatic_macOS_updates_darwin',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.osMgmt],
    },
    blockBigSurInstall: {
      name: 'block_macos_big_sur_install_darwin',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.osMgmt],
    },
    blockVenturaAppInstall: {
      name: 'block_macos_ventura_app_install_darwin',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.security],
    },
    camera: {
      name: 'camera_control_darwin',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.security],
    },
    custom: {
      name: 'custom_mdm_profile_darwin',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.configuration],
    },
    dictation: {
      name: 'dictation_darwin',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.productivity],
    },
    disableGuest: {
      name: 'disable_guest_darwin',
      categories: [configurationCategoriesList.security,
        configurationCategoriesList.compliance],
    },
    disableSiri: {
      name: 'disable_siri_darwin',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.compliance],
    },
    enrollment: {
      name: 'mdm_darwin',
      categories: [configurationCategoriesList.remoteMgmt],
    },
    fileVault: {
      name: 'filevault-2_darwin',
      categories: [configurationCategoriesList.security,
        configurationCategoriesList.compliance],
    },
    firewall: {
      name: 'local_firewall_darwin',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.network],
    },
    gatekeeper: {
      name: 'gatekeeper_darwin',
      categories: [configurationCategoriesList.security,
        configurationCategoriesList.compliance],
    },
    icloud: {
      name: 'icloud_access_darwin',
      categories: [configurationCategoriesList.security,
        configurationCategoriesList.compliance],
    },
    itunes: {
      name: 'itunes_music_darwin',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.swMgmt],
    },
    jcApp: {
      name: 'jumpcloud_app_controls_darwin',
      categories: [configurationCategoriesList.remoteMgmt],
    },
    lockScreen: {
      name: 'lock_screen_darwin',
      categories: [configurationCategoriesList.security,
        configurationCategoriesList.compliance],
    },
    loginText: {
      name: 'login_window_text_darwin',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.compliance],
    },
    loginWindow: {
      name: 'login_window_control_darwin',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.compliance],
    },
    macAnalytics: {
      name: 'mac_analytics_darwin',
      categories: [configurationCategoriesList.monitoring,
        configurationCategoriesList.reporting],
    },
    password: {
      name: 'password_modification_darwin',
      categories: [configurationCategoriesList.security,
        configurationCategoriesList.deviceAccess],
    },
    power: {
      name: 'power_controls_darwin',
      categories: [configurationCategoriesList.remoteMgmt,
        configurationCategoriesList.compliance],
    },
    removableStorage: {
      name: 'removable_storage_darwin',
      categories: [configurationCategoriesList.security,
        configurationCategoriesList.compliance],
    },
    screenRecording: {
      name: 'screen_recording_pppc_darwin',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.security],
    },
    spotlight: {
      name: 'spotlight_internet_results_darwin',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.configuration],
    },
    systemPreferences: {
      name: 'system_preferences_panes_darwin',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.configuration],
    },
    systemUnlock: {
      name: 'system_unlock_control_darwin',
      categories: [configurationCategoriesList.security,
        configurationCategoriesList.deviceAccess],
    },
    systemUpdate: {
      name: 'system_update_darwin',
      categories: [configurationCategoriesList.remoteMgmt,
        configurationCategoriesList.osMgmt],
    },
    wallpaper: {
      name: 'wallpaper_modification_darwin',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.compliance],
    },
    appNotificationsdarwin: {
      name: 'app_notifications_darwin',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.productivity],
    },
    restrictEACS: {
      name: 'restrictEACS_control_darwin',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.security],
    },
    ntpTimeServer: {
      name: 'ntp_server_policy_darwin',
      categories: [configurationCategoriesList.security,
        configurationCategoriesList.network],
    },
    disableiCloud: {
      name: 'disable_icloud_private_relay_darwin',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.security],
    },
    crowdStrikeFalcon: {
      name: 'crowdStrike_Falcon_MDM_Settings_darwin',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.security],
    },
    crowdStrikeFalconFirmware: {
      name: 'crowdStrike_Falcon_Firmware_Analysis_MDM_Settings_darwin',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.security],
    },
    applicationPrivacyPreferences: {
      name: 'application_privacy_preferences_darwin',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.security],
    },
    kernelExtension: {
      name: 'kernel_extension_darwin',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.security],
    },
    systemExtension: {
      name: 'system_extension_darwin',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.security],
    },
    encryptedDnsHTTPS: {
      name: 'encrypted_dns_https_darwin',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.security],
    },
    encryptedDnsTLS: {
      name: 'encrypted_dns_tls_darwin',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.security],
    },
    restrictUnlockBiometrics: {
      name: 'disable_unlock_with_biometrics_darwin',
      categories: [configurationCategoriesList.security,
        configurationCategoriesList.compliance],
    },
    watchUnlock: {
      name: 'disable_unlock_with_apple_watch_darwin',
      categories: [configurationCategoriesList.security,
        configurationCategoriesList.deviceAccess],
    },
    certificateInstallation: {
      name: 'certificates_darwin',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.security],
    },
    airPrintPolicy: {
      name: 'airprint_policy_darwin',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.configuration],
    },
    customFontPolicy: {
      name: 'custom_font_policy_darwin',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.productivity],
    },
    wifiPolicy: {
      name: 'wifi_configuration_darwin',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.network],
    },
    sentinelOne: {
      name: 'sentinelOne_permission_darwin',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.security],
    },
    blockInstallProfile: {
      name: 'block_install_profile_darwin',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.security],
    },
    delaySoftwareUpdate: {
      name: 'delay_software_updates_darwin',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.security],
    },
    allowLoginItem: {
      name: 'manage_login_items_darwin',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.security],
    },
    disableContentCaching: {
      name: 'disable_content_caching_darwin',
      categories: [configurationCategoriesList.security,
        configurationCategoriesList.network],
    },
    disablePasswordAutofill: {
      name: 'disable_password_autofill_darwin',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.security],
    },
    manageRapidSecurityResponse: {
      name: 'manage_rapid_security_response_darwin',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.security],
    },
    enforcedFingerprintTimeout: {
      name: 'enforced_fingerprint_timeout_darwin',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.security],
    },
    disablePasswordSharing: {
      name: 'disable_password_sharing_darwin',
      categories: [configurationCategoriesList.security,
        configurationCategoriesList.network],
    },
    disableAirDrop: {
      name: 'disable_airdrop_darwin',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.security],
    },
    disableAirPlay: {
      name: 'disable_airplay_darwin',
      categories: [configurationCategoriesList.security,
        configurationCategoriesList.network],
    },
    disableUSBRestrictedMode: {
      name: 'disable_usb_restricted_mode_darwin',
      categories: [configurationCategoriesList.security,
        configurationCategoriesList.deviceAccess],
    },
    httpProxy: {
      name: 'http_proxy_darwin',
      categories: [configurationCategoriesList.security,
        configurationCategoriesList.enterpriseSettings],
    },
    energySettings: {
      name: 'energy_settings_darwin',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.security],
    },
    malwarebytePreferences: {
      name: 'malwarebytes_privacy_preference_darwin',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.security],
    },
    dockPolicy: {
      name: 'dock_policy_darwin',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.productivity],
    },
    scep: {
      name: 'scep_profiles_darwin',
      categories: [configurationCategoriesList.enterpriseSettings],
    },
    softwareUpdatePreferences: {
      name: 'software_update_preferences_darwin',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.swMgmt],
    },
    contentCaching: {
      name: 'content_caching_darwin',
      categories: [configurationCategoriesList.security,
        configurationCategoriesList.network],
    },
    chromeExtensionPolicy: {
      name: 'google_chrome_browser_force_installed_extension_list_darwin',
      categories: [configurationCategoriesList.enterpriseSettings],
    },
    systemSettings: {
      name: 'system_settings_policy_darwin',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.configuration],
    },
    blockVeturaInstall: {
      name: 'block_macos_sonoma_app_install_darwin',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.security],
    },
    blockBluetooth: {
      name: 'block_bluetooth_file_exchange_darwin',
      categories: [configurationCategoriesList.enterpriseSettings],
    },
    restrictiPhoneMirroring: {
      name: 'restrict_iphone_mirroring_darwin',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.configuration],
    },
    restrictAppleIntelligence: {
      name: 'restrict_apple_intelligence_darwin',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.configuration],
    },
  },
  android: {
    accountRestrictionsPolicyAndroid: {
      name: 'account_restrictions_policy_android',
      categories: [configurationCategoriesList.configuration,
        configurationCategoriesList.security],
    },
    appBasedRestrictionsPolicyAndroid: {
      name: 'application_based_restrictions_policy_android',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.swMgmt],
    },
    batteryModePolicyAndroid: {
      name: 'battery_mode_policy_android',
      categories: [configurationCategoriesList.configuration,
        configurationCategoriesList.enterpriseSettings],
    },
    bluetoothRestrictionsPolicyAndroid: {
      name: 'bluetooth_restrictions_policy_android',
      categories: [configurationCategoriesList.network,
        configurationCategoriesList.security],
    },
    cellularRestrictionPolicyAndroid: {
      name: 'cellular_restrictions_policy_android',
      categories: [configurationCategoriesList.network,
        configurationCategoriesList.security],
    },
    commonCriteriaModeAndroid: {
      name: 'common_criteria_mode_android',
      categories: [configurationCategoriesList.network,
        configurationCategoriesList.security],
    },
    customPolicyAndroid: {
      name: 'custom_policy_android',
      categories: [configurationCategoriesList.configuration,
        configurationCategoriesList.enterpriseSettings],
    },
    deviceRestrictionsPolicyAndroid: {
      name: 'device_restrictions_policy_android',
      categories: [configurationCategoriesList.deviceAccess,
        configurationCategoriesList.security],
    },
    factoryResetProtectionPolicyAndroid: {
      name: 'factory_reset_protection_policy_android',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.security],
    },
    hardwareSecurityPolicyAndroid: {
      name: 'hardware_security_policy_android',
      categories: [configurationCategoriesList.deviceAccess,
        configurationCategoriesList.security],
    },
    kioskModePolicyAndroid: {
      name: 'kiosk_mode_policy_android',
      categories: [configurationCategoriesList.deviceAccess,
        configurationCategoriesList.enterpriseSettings],
    },
    locationServicesPolicyAndroid: {
      name: 'location_services_policy_android',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.monitoring],
    },
    lockScreenRestrictionPolicyAndroid: {
      name: 'lockscreen_restrictions_policy_android',
      categories: [configurationCategoriesList.deviceAccess,
        configurationCategoriesList.security],
    },
    passcodePolicyAndroid: {
      name: 'passcode_policy_android',
      categories: [configurationCategoriesList.compliance,
        configurationCategoriesList.security],
    },
    proxySettingAndroidPolicy: {
      name: 'proxy_settings_policy_android',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.network],
    },
    policyEnforcementRulePolicyAndroid: {
      name: 'policy_enforcement_rules_policy_android',
      categories: [configurationCategoriesList.compliance,
        configurationCategoriesList.security],
    },
    runtimePermissionsPolicyAndroid: {
      name: 'runtime_permissions_policy_android',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.swMgmt],
    },
    systemUpdatePolicyAndroid: {
      name: 'system_update_policy_android',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.osMgmt],
    },
    vpnRestrictionsPolicyAndroid: {
      name: 'vpn_restrictions_policy_android',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.network],
    },
    wifiConfigurationPolicyAndroid: {
      name: 'wifi_configuration_policy_android',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.network],
    },
    wifiRestrictionPolicyAndroid: {
      name: 'wifi_restrictions_policy_android',
      categories: [configurationCategoriesList.network,
        configurationCategoriesList.security],
    },
    workAndPersonalPolicyAndroid: {
      name: 'work_and_personal_policy_android',
      categories: [configurationCategoriesList.configuration,
        configurationCategoriesList.deviceAccess],
    },
  },
  ios: {
    certInstallation: {
      name: 'certificates_ios',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.security],
    },
    cloudPhoto: {
      name: 'disable_cloud_photo_library_ios',
      categories: [configurationCategoriesList.security,
        configurationCategoriesList.compliance],
    },
    custom: {
      name: 'custom_mdm_profile_ios',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.configuration],
    },
    disableAnalytics: {
      name: 'disable_analytics_ios',
      categories: [configurationCategoriesList.monitoring,
        configurationCategoriesList.reporting],
    },
    disableFacetime: {
      name: 'disable_facetime_ios',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.security],
    },
    disableSiri: {
      name: 'disable_siri_ios',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.compliance],
    },
    disableUnlockBiometrics: {
      name: 'disable_unlock_with_biometrics_ios',
      categories: [configurationCategoriesList.security,
        configurationCategoriesList.compliance],
    },
    managedAppRestrictions: {
      name: 'managed_app_restrictions_ios',
      categories: [configurationCategoriesList.swMgmt,
        configurationCategoriesList.security],
    },
    passcodeConfigurable: {
      name: 'passcode_configurable_ios',
      categories: [configurationCategoriesList.compliance,
        configurationCategoriesList.security],
    },
    passcodeForUserEnrolledDevices: {
      name: 'require_passcode_for_user_enrolled_devices_ios',
      categories: [configurationCategoriesList.compliance,
        configurationCategoriesList.security],
    },
    spotlight: {
      name: 'disable_spotlight_ios',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.configuration],
    },
    systemUnlock: {
      name: 'disable_unlock_with_apple_watch_ios',
      categories: [configurationCategoriesList.security,
        configurationCategoriesList.deviceAccess],
    },
    webclips: {
      name: 'webclip_ios',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.configuration],
    },
    wifi: {
      name: 'wifi_configuration_ios',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.network],
    },
    disableEACS: {
      name: 'disableEACS_control_ios',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.security],
    },
    appNotificationsios: {
      name: 'app_notifications_ios',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.productivity],
    },
    supervisedRestrictions: {
      name: 'supervised_restrictions_ios',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.security],
    },
    dnsHTTPS: {
      name: 'encrypted_dns_https_ios',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.security],
    },
    dnsTLS: {
      name: 'encrypted_dns_tls_ios',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.security],
    },
    airPrint: {
      name: 'airprint_policy_ios',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.configuration],
    },
    customFont: {
      name: 'custom_font_policy_ios',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.productivity],
    },
    activationLock: {
      name: 'activation_lock_ios',
      categories: [configurationCategoriesList.security,
        configurationCategoriesList.compliance],
    },
    handleRapidSecurityResponse: {
      name: 'manage_rapid_security_response_ios',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.security],
    },
    globalHttpProxy: {
      name: 'http_proxy_ios',
      categories: [configurationCategoriesList.security,
        configurationCategoriesList.enterpriseSettings],
    },
    scepProfile: {
      name: 'scep_profile_ios',
      categories: [configurationCategoriesList.enterpriseSettings],
    },
    blockApps: {
      name: 'block_apps_policy_ios',
      categories: [configurationCategoriesList.security,
        configurationCategoriesList.enterpriseSettings],
    },
    blockCustomApps: {
      name: 'block_custom_apps_ios',
      categories: [configurationCategoriesList.security,
        configurationCategoriesList.enterpriseSettings],
    },
    usbExternalStorageRestriction: {
      name: 'usb_storage_restriction_darwin',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.security],
    },
    blockWidgetSharing: {
      name: 'block_iOS_widget_sharing_policy_ios',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.security],
    },
    singleAppMode: {
      name: 'single_app_mode_ios',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.configuration],
    },
    restrictiPhoneMirroring: {
      name: 'restrict_iphone_mirroring_ios',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.configuration],
    },
    restrictAppleIntelligence: {
      name: 'restrict_apple_intelligence_ios',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.configuration],
    },
  },
  windows: {
    advancedCustomRegistryKeys: {
      name: 'custom_registry_keys_policy_windows',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.configuration],
    },
    allowSystemShutdownWithoutLogon: {
      name: 'allow_system_to_be_shutdown_without_having_to_logon_windows',
      categories: [configurationCategoriesList.enterpriseSettings],
    },
    allowBiometrics: {
      name: 'allow_the_use_of_biometrics_windows',
      categories: [configurationCategoriesList.security,
        configurationCategoriesList.compliance],
    },
    allowCamera: {
      name: 'allow_use_of_camera_windows',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.security],
    },
    bitLockerFullDiskEncryption: {
      name: 'bitlocker_fde_windows',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.security],
    },
    blockBluetooth: {
      name: 'bluetooth_configuration_mdm_windows',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.network],
    },
    blockMicrosoftAccounts: {
      name: 'block_microsoft_accounts_windows',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.compliance],
    },
    builtInAdminAccountStatus: {
      name: 'builtin_administrator_account_status_windows',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.compliance],
    },
    builtInGuestAccountStatusConfig: {
      name: 'disable_guest_windows',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.compliance],
    },
    certInstallation: {
      name: 'install_certificate_mdm_windows',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.security],
    },
    configureExplorer: {
      name: 'explorer_windows',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.configuration],
    },
    configureHelp: {
      name: 'help_windows',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.configuration],
    },
    configureQoS: {
      name: 'qos_windows',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.configuration],
    },
    configureWindowsUpdates: {
      name: 'system_updates_windows',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.osMgmt],
    },
    configureOspWindowsUpdates: {
      name: 'system_updates_osp_windows',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.osMgmt],
    },
    controlPanelDisplay: {
      name: 'controlpaneldisplay_windows',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.configuration],
    },
    deviceInstallation: {
      name: 'devinst_windows',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.security],
    },
    disableCortana: {
      name: 'disable_cortana_windows',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.configuration],
    },
    disableWindowsSpotlight: {
      name: 'disable_spotlight_features_windows',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.security],
    },
    disableWindowsStoreApp: {
      name: 'disable_windows_store_application_windows',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.configuration],
    },
    disableWinStoreAndUniversalWinPlatformApps: {
      name: 'disable_all_apps_from_windows_store_windows',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.configuration],
    },
    displayConfiguration: {
      name: 'display_windows',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.configuration],
    },
    displayUserInfoSessionLocked: {
      name: 'display_user_info_when_the_session_is_locked_windows',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.security],
    },
    dnsClient: {
      name: 'dnsclient_windows',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.network],
    },
    doNotDisplayLastUserLogonScreen: {
      name: 'do_not_display_last_username_windows',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.security],
    },
    doNotRequireCtrlAltDelLogonScreen: {
      name: 'do_not_require_cad_windows',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.compliance],
    },
    errorReporting: {
      name: 'wer_windows',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.compliance],
    },
    eventLogging: {
      name: 'eventlogging_windows',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.compliance],
    },
    feedbackNotifications: {
      name: 'FeedbackNotifications_windows',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.configuration],
    },
    fileRecovery: {
      name: 'filerecovery_windows',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.compliance],
    },
    findMyDevice: {
      name: 'findMyDevice_windows',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.security],
    },
    globalization: {
      name: 'intl_windows',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.configuration],
    },
    googleChromePreferences: {
      name: 'google_chrome_policy_windows',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.configuration],
    },
    jumpcloudAppControls: {
      name: 'jumpcloud_app_controls_windows',
      categories: [configurationCategoriesList.remoteMgmt,
        configurationCategoriesList.configuration],
    },
    lockScreen: {
      name: 'lock_screen_windows',
      categories: [configurationCategoriesList.security,
        configurationCategoriesList.compliance],
    },
    logon: {
      name: 'logon_windows',
      categories: [configurationCategoriesList.security,
        configurationCategoriesList.compliance],
    },
    messageTextUsersAttemptingLogon: {
      name: 'message_title_text_for_users_attempting_to_logon_windows',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.configuration],
    },
    microsoftAccount: {
      name: 'MicrosoftAccount_windows',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.configuration],
    },
    microsoftEdge: {
      name: 'MicrosoftEdge_windows',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.configuration],
    },
    mobilePcMobilityCenter: {
      name: 'mobilepcmobilitycenter_windows',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.configuration],
    },
    mozillaFirefoxPreferences: {
      name: 'mozilla_firefox_policy_windows',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.configuration],
    },
    msi: {
      name: 'msi_windows',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.configuration],
    },
    oneDrive: {
      name: 'onedrive_windows',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.configuration],
    },
    performanceDiagnostics: {
      name: 'performancediagnostics_windows',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.configuration],
    },
    powerManagement: {
      name: 'power_windows',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.configuration],
    },
    remoteAssistance: {
      name: 'remoteassistance_windows',
      categories: [configurationCategoriesList.remoteMgmt,
        configurationCategoriesList.configuration],
    },
    remoteManagement: {
      name: 'windowsremotemanagement_windows',
      categories: [configurationCategoriesList.remoteMgmt,
        configurationCategoriesList.configuration],
    },
    remoteShell: {
      name: 'winrs_windows',
      categories: [configurationCategoriesList.remoteMgmt,
        configurationCategoriesList.configuration],
    },
    removableStorage: {
      name: 'removablestorage_windows',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.security],
    },
    renameLocalAdminAccount: {
      name: 'rename_local_administrator_account_windows',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.configuration],
    },
    renameLocalGuestAccount: {
      name: 'rename_local_guest_account_windows',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.configuration],
    },
    restrictControlPanelAccess: {
      name: 'restrict_control_panel_panes_windows',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.configuration],
    },
    restrictSettingsAppVisibility: {
      name: 'settings_app_windows',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.configuration],
    },
    sharing: {
      name: 'sharing_windows',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.configuration],
    },
    softwareRestrictions: {
      name: 'software_restrictions_windows',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.swMgmt],
    },
    startMenu: {
      name: 'startmenu_windows',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.configuration],
    },
    systemRestore: {
      name: 'systemrestore_windows',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.configuration],
    },
    turnOffAutoplay: {
      name: 'turn_off_autoplay_windows',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.configuration],
    },
    vpnManagment: {
      name: 'vpn_v2_mdm_windows',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.network],
    },
    wifiConfigurationMdm: {
      name: 'wifi_configuration_mdm_windows',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.network],
    },
    windowsDefender: {
      name: 'windef_windows',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.osMgmt],
    },
    windowsFirewall: {
      name: 'windowsfirewall_windows',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.osMgmt],
    },
    winmaps: {
      name: 'winmaps_windows',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.configuration],
    },
    chromeExtensions: {
      name: 'chrome_force_extensions_windows',
      categories: [configurationCategoriesList.enterpriseSettings],
    },
    certificateScep: {
      name: 'scep_profiles_mdm_windows',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.network],
    },
    configRefresh: {
      name: 'config_refresh_mdm_windows',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.configuration],
    },
    customOmaUri: {
      name: 'custom_oma_uri_mdm_windows',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.configuration],
    },
    appRestriction: {
      name: 'application_restriction_mdm_windows',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.configuration],
    },
    windowsTimeSyncronization: {
      name: 'time_service_configuration_mdm_windows',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.network],
    },
    windowsAIMdmWindows: {
      name: 'windowsAI_mdm_windows',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.configuration],
    },
    storageSense: {
      name: 'storage_sense_mdm_windows',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.osMgmt, configurationCategoriesList.security],
    },
  },
  linux: {
    additionalProcessHardening: {
      name: 'additional_process_hardening_linux',
      categories: [configurationCategoriesList.security,
        configurationCategoriesList.compliance],
    },
    disableUnusedFilesystems: {
      name: 'disable_unused_filesystems_linux',
      categories: [configurationCategoriesList.security,
        configurationCategoriesList.compliance],
    },
    secureBootSettings: {
      name: 'secure_boot_settings_linux',
      categories: [configurationCategoriesList.security,
        configurationCategoriesList.compliance],
    },
    networkParameters: {
      name: 'network_parameters_linux',
      categories: [configurationCategoriesList.security,
        configurationCategoriesList.network],
    },
    serviceClients: {
      name: 'service_clients_linux',
      categories: [configurationCategoriesList.security,
        configurationCategoriesList.compliance],
    },
    disableUsbStorage: {
      name: 'disable_usb_storage_linux',
      categories: [configurationCategoriesList.security,
        configurationCategoriesList.compliance],
    },
    diskEncryptionCheck: {
      name: 'disk_encryption_check_linux',
      categories: [configurationCategoriesList.security,
        configurationCategoriesList.compliance],
    },
    lockScreen: {
      name: 'lock_screen_linux',
      categories: [configurationCategoriesList.security,
        configurationCategoriesList.compliance],
    },
    automaticUbuntuUpdates: {
      name: 'system_update_ubuntu_linux',
      categories: [configurationCategoriesList.enterpriseSettings,
        configurationCategoriesList.osMgmt],
    },
  },
  universal: {
    chromeUniversal: {
      name: 'chrome_universal',
    },
  },
};

export default configurationCategoriesConst;
