import { setupFeatureFlags } from '@jumpcloud/ui-feature-flags';

/* When a new FF is added, please make sure to add it alphabetically by localName */
const flagConfigs = [
  {
    localName: 'abmConnector',
    sourceId: 'LD-2024-06-03-show-ABM-app-in-catalog',
  },
  {
    localName: 'addDeviceComponentLibraryUpdates',
    sourceId: 'LD-2024-03-29-add-device-page-update',
  },
  {
    localName: 'adpIdmConnector',
    sourceId: 'LD-2023-10-06-adp-internal-scim-import',
  },
  {
    localName: 'androidDeviceLostMode',
    sourceId: 'LD-2024-06-24-android-lost-mode',
  },
  {
    localName: 'androidDpcExtras',
    sourceId: 'DR-1250-android-dpc-extras',
  },
  {
    localName: 'androidEmmTenantBasedSubdomain',
    sourceId: 'LD-2024-08-23-android-emm-tenant-based-subdomain',
  },
  {
    localName: 'androidIframeErrorTest',
    sourceId: 'DR-1764-android-emm-iframe-error-test',
  },
  {
    localName: 'appAsideToFullPageM1',
    sourceId: 'DC-1139-app-aside-to-full-page-m1',
  },
  {
    localName: 'appCatalogForApple',
    sourceId: 'DSM-80-app-catalog-for-apple',
  },
  {
    localName: 'browserPatchReport',
    sourceId: 'LD-2022-09-14-browser-patch-report',
  },
  {
    localName: 'createSSAPModalPolicy',
    sourceId: 'UN-2599-create-SSAP-modal-policy',
  },
  {
    localName: 'deviceGroupAttributeWindowsReleaseName',
    sourceId: 'ALP-1-dg-windows-release-name',
  },
  {
    localName: 'deviceHealthMonitoring',
    sourceId: 'LD-2024-03-01-monitoring',
  },
  {
    localName: 'deviceListFastFollow',
    sourceId: 'LD-2024-10-08-Device-List-Fast-Follow',
  },
  {
    localName: 'deviceListUpdates',
    sourceId: 'LD-2024-06-10-Device-List-Update',
  },
  {
    localName: 'deviceLostMode',
    sourceId: 'LD-2024-04-02-enable-disable-lost-mode',
  },
  {
    localName: 'enhancedReportingUX',
    sourceId: 'LD-2024-08-23-enhanced-reporting-ux',
  },
  {
    localName: 'globalMfaEnforcementOptout',
    sourceId: 'LD-2024-07-02-global-mfa-enforcement-neworgs-optout',
  },
  {
    localName: 'googleUserImportFilter',
    sourceId: 'DC-280-google-user-import-filter',
  },
  {
    localName: 'idmSupportCeridianDayforceHcm',
    sourceId: 'LD-2023-04-12-dayforce-hr-user-import',
    defaultValue: true,
  },
  {
    localName: 'modernAdminAuthCAPs',
    sourceId: 'ZR-1041-modern-admin-auth-caps',
  },
  {
    localName: 'notificationChannels',
    sourceId: 'GIR-956-email-notification-service',
  },
  {
    localName: 'odsReports',
    sourceId: 'LD-2024-07-15-ods-reports',
  },
  {
    localName: 'osPatchMgmtPolicyReport',
    sourceId: 'LD-2022-06-27-os-patch-management-report',
  },
  {
    localName: 'passwordComplexity',
    sourceId: 'LD-2024-04-22-password-complexity',
  },
  {
    localName: 'policyManagementDeliveryType',
    sourceId: 'TH-750-ap-policy-management-delivery-type',
  },
  {
    localName: 'primarySystemUserOnDevice',
    sourceId: 'HY-3131-primary-user-on-a-device-phase-1',
  },
  {
    localName: 'remoteAssist',
    sourceId: 'LD-2022-08-01-remote-assist',
    defaultValue: true,
  },
  {
    localName: 'remoteAssistTenantBasedFlow',
    sourceId: 'BAN-2607-remote-access-eu-tenant-subdomain',
  },
  {
    localName: 'requestApprovalWorkflow',
    sourceId: 'LD-2024-07-15-request-approval-workflow',
  },
  {
    localName: 'saasManagementConnectors',
    sourceId: 'UA-492-saas-management-connectors',
  },
  {
    localName: 'saasManagementNewTableMigration',
    sourceId: 'UA-492-new-table-schemas-switched-orgs',
  },
  {
    localName: 'serviceAccounts',
    sourceId: 'LD-2024-10-18-enable-service-accounts',
    defaultValue: true,
  },
  {
    localName: 'ssoReport',
    sourceId: 'LD-2022-04-13-sso-report',
  },
  {
    localName: 'sp1888ShowSelectedCount',
    sourceId: 'plx-qw-selected-count',
  },
  {
    localName: 'synthesizedReports',
    sourceId: 'LD-2021-04-01-synthesized-reports',
  },
  {
    localName: 'TBTSurveyUpdates',
    sourceId: 'LD-2024-06-13-TBT-Survey',
  },
  {
    localName: 'testFlag',
    sourceId: 'NE-attribute-test-flag',
  },
  {
    localName: 'updatePrebuiltConnector',
    sourceId: 'CS-5055-prebuilt-credentials-update',
  },
  {
    localName: 'updatedSubmitCase',
    sourceId: 'LD-2024-05-14-Updated-Submit-Case-AP',
  },
  {
    localName: 'usePaginationMethodForBoundDevices',
    sourceId: 'LD-2023-01-10-show-bound-devices-traversals',
  },
  {
    localName: 'userListUpdates',
    sourceId: 'LD-2024-10-21-user-list-updates',
  },
  {
    localName: 'usersToDevicesReport',
    sourceId: 'LD-2022-09-14-users-to-devices-report',
    defaultValue: true,
  },
  {
    localName: 'usersToDirectoriesReport',
    sourceId: 'LD-2022-09-14-users-to-directories-report',
    defaultValue: true,
  },
  {
    localName: 'usersToLdapServersReport',
    sourceId: 'LD-2022-09-14-users-to-ldap-servers-report',
    defaultValue: true,
  },
  {
    localName: 'usersToRadiusServersReport',
    sourceId: 'LD-2022-09-14-users-to-radius-servers-report',
    defaultValue: true,
  },
  {
    localName: 'usersToUserGroupsReport',
    sourceId: 'LD-2022-12-20-users-to-user-groups-report',
  },
  {
    localName: 'vendorColumnAndFilter',
    sourceId: 'plx-qw-vendor',
  },
  {
    localName: 'testFlag',
    sourceId: 'NE-attribute-test-flag',
  },
  {
    localName: 'googleUserImportFilter',
    sourceId: 'DC-280-google-user-import-filter',
  },
  {
    localName: 'policyManagementDeliveryType',
    sourceId: 'TH-750-ap-policy-management-delivery-type',
  },
  {
    localName: 'notificationChannels',
    sourceId: 'GIR-956-email-notification-service',
  },
  {
    localName: 'tenantBasedSubdomain',
    sourceId: 'SR-1338-tenant-based-subdomain',
  },
  {
    localName: 'readOnlyScimAttributeMappingsM1',
    sourceId: 'DC-1169-read-only-SCIM-attribute-mappings-m1',
  },
  {
    localName: 'androidRestartBulkAction',
    sourceId: 'plx-qw-android-restart',
  },
];

/**
 * Maintaining this const for legacy reasons. We are attempting to move away from it,
 * but updating all the references to this all at once was too big an effort at this time.
 *
 * @deprecated Just reference the flag names directly. No need for a intermediary const
 */
export const featureFlags = flagConfigs.reduce((prevFlags, flag) => ({
  ...prevFlags,
  // Build a mapping where the localName is both key and value.
  [flag.localName]: flag.localName,
}), {});

export const {
  initializeFlags,
  useFeatureFlags,
  FeatureFlagsModule,
  FeatureFlagMixin,
} = setupFeatureFlags(flagConfigs);

/**
 * This function is being exported for legacy tests
 * It is not expected to be used outside of tests.
 * @deprecated
 */
export function createFeatureFlagsModule() {
  return setupFeatureFlags(flagConfigs).FeatureFlagsModule;
}

export default FeatureFlagsModule;
